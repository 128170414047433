/* General Styles */
html {
  font-family: Roboto, sans-serif;
}

.App {
  text-align: center;
}

/* Logo Styles */
.App-logo {
  height: 40vmin;
  max-width: 100%;
  pointer-events: none;
}

/* Typography Styles */
.font-title {
  font-family: 'Bebas Neue', cursive, sans-serif !important;
}

/* Button Styles */
.enter_button {
  background-image: linear-gradient(to right, #102053 0%, #2970fc 47%, #193079 94%) !important;
  background-size: 130%;
  background-position: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  color: #fff;
  padding: 15px 20px;
  border-radius: 5px;
  transition: all 0.3s ease;
  text-decoration: none;
  cursor: pointer;
  border-width: 0;
  margin-top: 20px;
}

.enter_button .icon {
  flex: 0 0 auto;
  background: transparent;
  color: #fff !important;
  width: auto;
  height: auto;
  line-height: 1 !important;
  margin: 0 0 0 5px !important;
  padding: 0 !important;
}

.enter_button .text {
  text-align: center;
}

/* Title Styles */
.gv-hero__title {
  font-weight: bold !important;
  font-size: 68px;
  line-height: 1;
  text-transform: uppercase;
  background: linear-gradient(to right, #9a6835 0%, #9a6835 10%, #eac35a 20%, #eac35a 50%, #9a6835 75%, #eac35a 85%);
  color: transparent;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  filter: drop-shadow(4px 0px #413023);
  margin: 10px 0 0;
  letter-spacing: 3px;
}

/* Responsive Styles */
@media (max-width: 767px) {
  .gv-hero__title {
    font-size: 36px;
    filter: drop-shadow(2px 0px #413023);
  }
}

/* Header Styles */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Animation Styles */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Additional Styles for Dashboard Display */
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 0.3s ease;
}

.dashboard-content .logo-container {
  flex-direction: row;
  transition: all 0.3s ease;
}

.dashboard-content .logo-container img {
  height: auto;
  width: 150px;
  transition: all 0.3s ease;
}

.dashboard-content .gv-hero__title {
  font-size: 36px;
  transition: all 0.3s ease;
}

.dashboard-content canvas {
  max-width: 90%;
  margin: 0 auto;
  max-height: 500px;
  height: 100% !important;
  width: 100% !important;
}

.dashboard-content .chart-wrapper {
  position: relative;
  height: 40vh;
  width: 80vw;
  max-width: 768px;
  margin: 0 auto;
}

.values-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
}

.values-container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.values-container h3 {
  font-size: 18px;
  margin: 0;
}

@media (max-width: 768px) {
  .values-container {
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }
}
